import { CAMPAIGN_TYPE } from "./campaign";

export const D2C_BANNER = [
  // remove the below if passed 12 sept
  {
    lang: "en",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/mag-test-2.png",
    show: true,
    alt: "",
  },
  {
    lang: "en",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/malaysia-free-roadtax-en.png",
    show: true,
    alt: "",
    hideIf: [CAMPAIGN_TYPE.roadtaxMalaysianCar, CAMPAIGN_TYPE.roadtaxSept14],
  },
  {
    lang: "bm",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/malaysia-free-roadtax-bm.png",
    show: true,
    alt: "",
    hideIf: [CAMPAIGN_TYPE.roadtaxMalaysianCar, CAMPAIGN_TYPE.roadtaxSept14],
  },
  {
    lang: "en",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/Shopee_D2C_ENG.png",
    show: true,
    alt: "",
  },
  {
    lang: "bm",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/Shopee_D2C_BM.png",
    show: true,
    alt: "",
  },
  {
    lang: "en",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/psbelanja15-en.png",
    show: true,
    alt: "",
    hideIf: [CAMPAIGN_TYPE.roadtaxMalaysianCar],
  },
  {
    lang: "bm",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/psbelanja15-bm.png",
    show: true,
    alt: "",
    hideIf: [CAMPAIGN_TYPE.roadtaxMalaysianCar],
  },
  {
    lang: "en",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/cta_banner_main-en.png",
    show: true,
    alt: "",
    hideIf: [CAMPAIGN_TYPE.roadtaxMalaysianCar, CAMPAIGN_TYPE.roadtaxSept14],
  },
  {
    lang: "bm",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/cta_banner_main-bm.png",
    show: true,
    alt: "",
    hideIf: [CAMPAIGN_TYPE.roadtaxMalaysianCar, CAMPAIGN_TYPE.roadtaxSept14],
  },
];

export const D2C_MOBILE_BANNER = [
  // remove the below if passed 12 sept
  {
    lang: "en",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/mag-test-2.png",
    show: true,
    alt: "",
  },
  {
    lang: "en",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/malaysia-free-roadtax-en.png",
    show: true,
    alt: "",
    hideIf: [CAMPAIGN_TYPE.roadtaxMalaysianCar, CAMPAIGN_TYPE.roadtaxSept14],
  },
  {
    lang: "bm",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/malaysia-free-roadtax-bm.png",
    show: true,
    alt: "",
    hideIf: [CAMPAIGN_TYPE.roadtaxMalaysianCar, CAMPAIGN_TYPE.roadtaxSept14],
  },
  {
    lang: "en",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/Shopee_D2C_ENG.png",
    show: true,
    alt: "",
  },
  {
    lang: "bm",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/Shopee_D2C_BM.png",
    show: true,
    alt: "",
  },
  {
    lang: "en",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/psbelanja15-en.png",
    show: true,
    alt: "",
    hideIf: [CAMPAIGN_TYPE.roadtaxMalaysianCar],
  },
  {
    lang: "bm",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/psbelanja15-bm.png",
    show: true,
    alt: "",
    hideIf: [CAMPAIGN_TYPE.roadtaxMalaysianCar],
  },
  {
    lang: "en",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/cta_banner_main-mobile-en.png",
    show: true,
    alt: "",
    hideIf: [CAMPAIGN_TYPE.roadtaxMalaysianCar, CAMPAIGN_TYPE.roadtaxSept14],
  },
  {
    lang: "bm",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/cta_banner_main-mobile-bm.png",
    show: true,
    alt: "",
    hideIf: [CAMPAIGN_TYPE.roadtaxMalaysianCar, CAMPAIGN_TYPE.roadtaxSept14],
  },
];

export const DEFAULT_CTA_BANNER = [
  {
    lang: "en",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/cta_banner_mini-en.png",
    show: true,
    alt: "",
  },
  {
    lang: "bm",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/cta_banner_mini-bm.png",
    show: true,
    alt: "",
  },
];
